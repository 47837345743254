export enum APIEndpointEnum {
  PrepareTraditionalEnable2FA = 'api/v1/auth/totp/enable/traditional/prepare/',
  PrepareWalletEnable2FA = 'api/v1/auth/totp/enable/wallet/prepare/',
  GetSignInSettingWallet = 'api/v1/auth/login/web3/setting/get/',
  PrepareEnable2FAResendEmail = 'api/v1/auth/totp/enable/traditional/email/resend/',
  TraditionalEnable2FA = 'api/v1/auth/totp/enable/traditional/confirm/',
  WalletEnable2FA = 'api/v1/auth/totp/enable/wallet/confirm/',
  Disable2FA = 'api/v1/auth/totp/disable/',
  GetUser2FAStatus = 'api/v1/user/setting/get/',
  Games = 'api/v1/game/list/',
  BonusGameCollection = 'api/v1/voucher/wager/game-collection/game/list/',
  BonusGameCollectionNonLogin = 'api/v1/promotion/game-collection/game/list/',
  WageringBonusGameCodes = 'api/v1/voucher/wager/game-code/list/',
  CreateGameSession = 'api/v1/game/session/create/',
  GameDetail = 'api/v1/game/get/',
  CreateGameSessionDemo = 'api/v1/game/demo/',
  ClientCountry = 'api/v1/country/get/',
  GameDescription = 'api/v1/game/description/get/',
  GameWagerMultiplier = 'api/v1/promotion/game-collection/game/get/',
  RecommendedGames = 'api/v1/game/recommendation/',
  UserGameRating = 'api/v1/game/rating/get/',
  GameRatingInfo = 'api/v1/game/rating/get/external/',
  GameRatingVote = 'api/v1/game/rating/vote/',
  CreditBonusRestrictedGames = 'api/v1/game/voucher-c2p-restricted/list/',

  SportGameInfo = 'api/v1/game/session/create/',
  BannerList = 'api/v1/homepage/banner/list/',
  HomeCategoryList = 'api/v1/menu/category/list/',
  ScreenTagList = 'api/v1/menu/tag/list/',
  GameCollectionList = 'api/v1/game/collection/list/',
  GameCollectionDetail = 'api/v1/game/collection/get/',
  ProviderList = 'api/v1/game/provider/list/',
  Provider = 'api/v1/game/provider/get/',
  TopWinList = 'api/v1/top/win/list/',
  GameDetailTopWinList = 'api/v1/social/top/win/list/',
  LiveBetList = 'api/v1/social/bet/list/',
  BetDetail = 'api/v1/social/bet/detail/',
  ProfileGettingStartedTaks = 'api/v1/user/getting-started/get/',
  GettingStartedCheckoutWelcomePack = 'api/v1/promotion/welcome/package/checkout/',
  ProfileUpdateGettingStartedTask = 'api/v1/user/getting-started/claim/',
  ProfileStatisticGameInfoes = 'api/v1/user/statistics/game/',
  ProfileStatisticBonuses = 'api/v1/user/statistics/bonus/',
  Avatar = 'api/v1/avatar/list/',
  DisplayName = 'api/v1/user/display-name/get/',
  UserUpdate = 'api/v1/user/info/update/',
  PrepareUserUpdateProfile = 'api/v1/user/info/update/prepare/',
  UserFavoriteGameCodes = 'api/v1/user/favorite/list/',
  UserFavoriteGames = 'api/v1/user/favorite/game/list/',
  UserRecentGames = 'api/v1/game/recent/list/',
  AddFavoriteGame = 'api/v1/user/favorite/game/add/',
  RemoveFavoriteGame = 'api/v1/user/favorite/game/remove/',
  UserProfile = 'api/v1/user/other-profile/get/',
  UserTipStatistics = 'api/v1/tip/statistics/',
  UserTotalWager = 'api/v1/user/statistics/wager/',
  FindUserData = 'api/v1/user/get-by-display-name/',
  LinkAccountTelegram = 'api/v1/auth/telegram/link/',
  UnLinkAccountTelegram = 'api/v1/auth/telegram/unlink/',
  TelegramStartCode = 'api/v1/auth/telegram/code-connect/',
  MerchantBetby = 'api/v1/merchant/betby/game/jwt/gen/',
  Meta = 'api/v1/meta/get/',
  User = 'api/v1/user/get/',
  UserUpdateSetting = 'api/v1/user/setting/update/',
  UserUpdate2FASetting = '/api/v1/user/setting/totp/update/',
  DepositBonus = 'api/v1/promotion/user/deposit/info/get/',
  TokenUsdPrices = 'api/v1/currency/list/',
  MyTier = 'api/v1/user/tier/get/',
  PromotionList = 'api/v1/promotion/content/list/',
  PromotionDetail = 'api/v1/promotion/content/get/',
  Posts = 'api/content/posts/',
  Tags = 'api/content/tags/',
  TagDetails = 'api/content/tags/slug/{{slug}}/',
  PostDetails = 'api/content/posts/slug/{{slug}}/',
  PrivateSocket = '/api/v1/socket.io/',
  PublicSocket = '/api/v1/socket.io/public',
  CashbackMetadata = '/api/v1/cashback/meta/get/',
  CashbackEndDate = '/api/v1/cashback/timeline/get/',
  CashbackTransaction = '/api/v1/cashback/list/',
  CashbackTotalEarned = '/api/v1/cashback/sum/',
  CashbackClaim = '/api/v1/cashback/claim/',
  WalletLoginPrepare = 'api/v1/auth/login/prepare/',
  SignInByWallet = 'api/v1/auth/login/web3/execute/',
  Register = 'api/v1/user/register/execute/',
  Refresh = 'api/v1/auth/refresh/',
  SignUpPrepare = 'api/v1/user/register/prepare/',
  VerifyRegisterUser = 'api/v1/user/register/verification/request/',
  VerifyResetPassword = 'api/v1/auth/login/input/password/reset/prepare/',
  ValidateResetPassword = 'api/v1/auth/login/input/password/reset/validate/',
  SubmitResetPassword = 'api/v1/auth/login/input/password/reset/execute/',
  PrepareTraditionalLogin = 'api/v1/auth/login/input/prepare/',
  SignInByEmail = 'api/v1/auth/login/input/pwd/submit/',
  SignInByEmailWith2FA = 'api/v1/auth/login/input/totp/submit/',
  SignInByTelegram = 'api/v1/auth/login/telegram/execute/',
  validateLinkAccounteTelegram = 'api/v1/auth/telegram/check/',
  Web3UserVerifyExistedEmail = 'api/v1/user/email/add/prepare/',
  Web3UserAddEmail = 'api/v1/user/email/add/execute/',
  Web3UserAddEmailPrepare = 'api/v1/user/email/add/verification/request/',
  Balances = 'api/v1/payment/balance/list/',
  TraditionalDeposit = 'api/v1/payment/deposit/transfer/prepare/',
  WithdrawInfo = 'api/v1/payment/withdrawal/info/get/',
  DepositRollover = 'api/v1/user/voucher/simple/list/',
  PrepareTraditionalWithdraw = '/api/v1/payment/withdrawal/traditional/prepare/',
  PrepareWalletWithdraw = '/api/v1/payment/withdrawal/wallet/prepare/',
  PrepareWalletWithdrawFiat = '/api/v1/payment/withdrawal/fiat/wallet/prepare/',
  TraditionalWithdraw = '/api/v1/payment/withdrawal/traditional/submit/',
  TraditionalWithdrawFiat = '/api/v1/payment/withdrawal/fiat/traditional/submit/',
  WalletWithdraw = '/api/v1/payment/withdrawal/wallet/submit/',
  WalletWithdrawFiat = '/api/v1/payment/withdrawal/fiat/wallet/submit/',
  WithdrawalTransaction = '/api/v1/payment/withdrawal/get/',
  WithdrawalTransactions = '/api/v1/payment/withdrawal/list/',
  DepositFiatBankMethodList = '/api/v1/payment/deposit/fiat/bank/list/',
  FiatDeposit = '/api/v1/payment/deposit/fiat/submit/',
  GetDepositInfoByTxnHash = '/api/v1/payment/deposit/fiat/tx/get/',
  WithdrawFiatBankMethodList = '/api/v1/payment/withdrawal/fiat/bank/list/',
  PrepareTraditionalWithdrawFiat = '/api/v1/payment/withdrawal/fiat/traditional/prepare/',
  WithdrawFiatLockBonusBalanceInfo = '/api/v1/payment/withdrawal/fiat/info/get/',
  ExportDepositHistory = 'api/v1/payment/deposit/list/export/',
  ExportWithdrawHistory = 'api/v1/payment/withdrawal/list/export/',
  ExportBetHistory = 'api/v1/user/bet/list/export/',
  ExportBonusHistory = 'api/v1/user/voucher/history/list/export/',
  ExportCashbackHistory = 'api/v1/cashback/list/export/',
  ExportHusdHistory = 'api/v1/bonus/balance/txn/list/export/',
  ExportLuckySpinHistory = 'api/v1/spin/txn/info/export/',
  ExportRankingBonusHistory = 'api/v1/tier/bonus/list/export/',
  ExportRewardBonusHistory = 'api/v1/reward/list/export/',
  ExportSwapBonusHistory = 'api/v1/bonus/balance/convert/list/export/',
  ExportTipHistory = 'api/v1/tip/list/export/',
  ExportReferralHistory = 'api/v1/referral/bonus/claim/list/export/',
  ExportDailyCheckinHistory = 'api/v1/promotion/daily-checkin/list/export/',
  ExportTournamentHistory = 'api/v1/tournament/reward/tx/list/export/',

  PrepareWalletTip = '/api/v1/tip/web3/prepare/',
  WalletSendTip = '/api/v1/tip/web3/execute/',
  TipTinfo = '/api/v1/tip/info/get/',
  PrepareTraditionalTip = '/api/v1/tip/traditional/prepare/',
  TraditionalSendTip = '/api/v1/tip/traditional/execute/',
  TipDetails = '/api/v1/tip/get/',
  ReferralMeta = '/api/v1/referral/meta/get/',
  ReferralOverview = '/api/v1/referral/overview/get/',
  ReferralListCampaign = '/api/v1/referral/campaign/list/',
  ReferralDefaultCampaign = '/api/v1/referral/campaign/default/get/',
  ReferralAvailableBalanceClaim = '/api/v1/referral/balance/list/',
  ReferralTotalEarned = '/api/v1/referral/balance/claim/sum/',
  ReferralPrepareClaim = 'api/v1/referral/balance/claim/prepare/',
  ReferralClaimBalance = 'api/v1/referral/balance/claim/',
  ReferralCreateCampaign = 'api/v1/referral/campaign/create/',
  ReferralUpdateCampaign = 'api/v1/referral/campaign/update/',
  RefereeList = 'api/v1/referral/referee/list/',
  ReferralBonus = 'api/v1/referral/tier/bonus/get/',
  ReferralBonusClaim = 'api/v1/referral/tier/bonus/claim/',
  ReferralBonusClaimList = 'api/v1/referral/bonus/claim/list/',
  ReferralLiveReward = 'api/v1/referral/live/reward/get/',
  DepositTransaction = 'api/v1/payment/deposit/get/hash/',
  DepositTransactions = 'api/v1/payment/deposit/list/',
  ResumeDepositFiat = 'api/v1/payment/deposit/fiat/tx/resume/',
  BonusTransactionsHistory = 'api/v1/user/voucher/history/list/',
  RewardTransactions = 'api/v1/reward/list/',
  BonusTransactions = '/api/v1/tier/bonus/list/',
  SwapTransactions = '/api/v1/bonus/balance/convert/list/',
  BetTransactions = '/api/v1/user/bet/list/',
  TipTransactions = '/api/v1/tip/list/',
  SubscribeDeposit = '/api/v1/payment/deposit/address/watcher/submit/',
  StakingPoolStats = 'api/v1/staking/stats/get/',
  StakingMyPoolStats = 'api/v1/staking/balance/list/',
  StakingExecuteStake = 'api/v1/staking/balance/stake/',
  StakingExecuteUnstake = 'api/v1/staking/balance/un-stake/',
  StakingAvailableClaim = 'api/v1/staking/reward/claimable/list/',
  StakingTotalEarned = 'api/v1/staking/reward/claim/total/list/',
  StakingTransaction = 'api/v1/staking/balance/txn/list/',
  StakingExecuteClaim = 'api/v1/staking/reward/claim/',
  HUSDLockBalance = '/api/v1/bonus/balance/list/',
  HUSDClaimableBalance = '/api/v1/bonus/balance/unlock/prepare/',
  ClaimHUSD = '/api/v1/bonus/balance/unlock/execute/',
  UnlockFormula = '/api/v1/bonus/balance/unlock/formula/get/',
  TotalHUSDClaimed = '/api/v1/bonus/balance/unlock/sum/',
  HUSDTxnList = '/api/v1/bonus/balance/txn/list/',
  SwapHUSD = '/api/v1/bonus/balance/convert/execute/',
  PrepareSwapData = '/api/v1/bonus/balance/convert/prepare/',
  DailyCheckin = '/api/v1/promotion/daily-checkin/info/',
  DailyCheckinHasSession = '/api/v1/promotion/daily-checkin/info/get/',
  DailyCheckinClaim = '/api/v1/promotion/daily-checkin/claim/',
  DailyCheckinHistory = '/api/v1/promotion/daily-checkin/list/',
  SpinPrizeTransactions = '/api/v1/spin/txn/list/',
  SpinMyPrizeTransactions = '/api/v1/spin/txn/info/',
  SpinExternalPrizeList = '/api/v1/spin/get/external/',
  SpinInternalPrizeList = '/api/v1/spin/get/',
  SpinPlay = '/api/v1/spin/play/',
  SpinFreePlayTimes = '/api/v1/spin/balance/',
  SubscribeDepositByHash = '/api/v1/payment/deposit/watch/hash/submit/',
  QueryDepositByHash = '/api/v1/payment/deposit/watch/hash/get/',
  GetSystemNotifications = '/api/v1/notification/platform/list/',
  GetPrivateNotifications = '/api/v1/notification/personal/list/',
  MarkAsReadPrivateNotification = '/api/v1/notification/personal/mark-as-read/',
  MarkAllAsReadPrivateNotification = '/api/v1/notification/personal/mark-all-as-read/',
  MarkAsReadSystemNotification = '/api/v1/notification/platform/mark-as-read/',
  MarkAllAsReadSystemNotification = '/api/v1/notification/platform/mark-all-as-read/',
  BonusRedeem = '/api/v1/voucher/redeem/',
  FreespinBonusRegister = '/api/v1/user/voucher/free-round/register/',
  NoDepositBonusActive = '/api/v1/user/voucher/bonus/confirm/',
  ActivatedBonuses = '/api/v1/user/voucher/active/list/',
  UserBonuses = '/api/v1/user/voucher/list/',
  BonusApply = '/api/v1/user/voucher/apply/',
  BonusClaim = '/api/v1/user/voucher/bonus/claim/',
  BonusCancel = '/api/v1/user/voucher/cancel/confirm/',
  BonusPrepareCancel = '/api/v1/user/voucher/cancel/prepare/',
  BonusDetails = '/api/v1/voucher/get/',
  UserBonusDetails = '/api/v1/user/voucher/get/',
  BonusPrepareDeposit = '/api/v1/user/voucher/deposit/prepare/',
  ReselectWageringBonus = '/api/v1/user/voucher/wager/reselect/',
  JourneyBonuses = '/api/v1/user/voucher/journey/list/',
  MetaTag = '/api/v1/page/meta/tag/get/',
  WelcomePackage = '/api/v1/promotion/welcome/package/get/',
  UserWelcomePackageInfo = '/api/v1/promotion/welcome/package/user/stats/',
  UserChooseWelcomePackage = '/api/v1/promotion/welcome/package/choose/',
  FreeBonusesCampaign = '/api/v1/promotion/free-bonus-campaign/active/get/',
  NextFreeBonusesCampaign = 'api/v1/promotion/free-bonus-campaign/incoming/get/',
  FreeBonusCampaignClaim = '/api/v1/promotion/free-bonus-campaign/bonus/redeem/',
  ActiveAdventure = 'api/v1/promotion/adventure/active/get/',
  ExpireAdventure = 'api/v1/promotion/adventure/expired/get/',
  ListUserReward = '/api/v1/promotion/adventure/top/list/',
  AdventureUserStats = '/api/v1/promotion/adventure/user/stats/',
  AirdropList = '/api/v1/airdrop/user/reward/list/',
  ReceivedAirdropSummary = '/api/v1/airdrop/user/summary/',
  ClaimAirdrop = '/api/v1/airdrop/user/reward/claim/',

  // InHouse Game
  InhouseGameSubmitBet = '/api/v1/play/bet/',
  InhouseFinishBet = '/api/v1/play/end-round/',
  GetSeed = '/api/v1/seed/get/',
  UpdateSeed = '/api/v1/seed/update/',
  InhouseVerifyPayout = '/api/v1/play/fairness/',
  GetBetInfo = '/api/v1/play/round/get/',
  MineGameOpenBlock = '/api/v1/play/bet/next/',
  ChatHistory = '/api/v1/chat/history/',
  FiatCurrencyConversionRateInUSD = '/api/v1/currency/list/',
  UserSurvey = '/api/v1/survey/user/answer/add/',
  GuestSurvey = '/api/v1/survey/guest/answer/add/',
  CheckSurvey = '/api/v1/survey/user/answer/check/',

  TournamentList = '/api/v1/tournament/user/list/',
  TournamentListNonLogin = '/api/v1/tournament/list/',
  TournamentDetails = '/api/v1/tournament/get/',
  TournamentLeaderBoard = '/api/v1/tournament/leaderboard/list/',
  TournamentLeaderboardUserInfo = '/api/v1/tournament/user/get/',
  TournamentTransaction = '/api/v1/tournament/reward/tx/list/',
  JoinTournament = 'api/v1/tournament/user/join/',
  TournamentDetailByGameSlug = 'api/v1/tournament/in-game/get/',
}

export const BASE_API = process.env.NEXT_PUBLIC_APP_API
export const INHOUSE_GAME_API = process.env.NEXT_PUBLIC_INHOUSE_GAME_API
export const BASE_GHOST_API = process.env.NEXT_PUBLIC_GHOST_API
export const GHOST_API_KEY = process.env.NEXT_PUBLIC_GHOST_API_KEY
export const SERVER_JSON = process.env.NEXT_PUBLIC_SERVER_JSON

export const BRAND_ID_BETBY = process.env.NEXT_PUBLIC_BRAND_ID_BETBY
export const GAME_CODE_BETBY = process.env.NEXT_PUBLIC_GAME_CODE_BETBY
export const URL_BETBY = process.env.NEXT_PUBLIC_URL_BETBY

export const CRISP_ID = process.env.NEXT_PUBLIC_CRISP_ID || '53c1e663-aab8-4ce4-b172-155328f494cf'

export const HUNNY_BOT_TELEGRAM = process.env.NEXT_PUBLIC_HUNNY_BOT_TELEGRAM || 'hunnyplay_bot'

export const EXCLUDE_API_ERROR = ['error_auth', 'error_data_not_found', 'error_country_banned']
export const EXCLUDE_API_ERROR_LOG = ['error_data_not_found', 'error_country_banned']
export const USER_BANNED_ERROR_CODE = 'error_auth_blocked'
export const USER_LOCKED_WITHDRAW_ERROR_CODE = 'error_maintenance'

